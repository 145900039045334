<template>
    <div class="filter-group">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FilterGroup',
}
</script>

<style lang="scss" scoped>
.filter-group {
    // padding-right: 10px;
    // padding-left: 10px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    background-color: #ffffff;
}

.filter-item + .filter-item {
    border-top: 1px dashed #eeeeee;
}
</style>