<template>
    <div class="filter-item">
        <div class="filterItem-label">{{label||''}}</div>
        <div
            class="filterItem-content"
            :class="{'filterItem-content--fold':fold&&!unfold,'filterItem-content--unfold':fold&&unfold}"
        >
            <slot>
                <div
                    v-for="(item,index) in cpu_list"
                    :key="index"
                    class="content-item"
                    :class="{'content-item--active':cpu_isActive(item)}"
                    @click="selectItem(item)"
                >
                    {{item[labelKey]}}
                </div>
                <slot name="append"></slot>
            </slot>
        </div>
        <div
            v-if="fold"
            class="filterItem-right"
            :class="{'filterItem-right--unfold':unfold}"
            @click="toggleFold"
        >
            <span>{{unfold?'折叠':'展开'}}</span>
            <i class="el-icon-caret-bottom icon"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterItem',
    props: {
        value: {},
        label: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: () => []
        },
        // 是否需要不限
        needAll: {
            type: Boolean,
            default: true,
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        labelKey: {
            type: String,
            default: 'label'
        },
        // 是否开启展开折叠
        fold: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            unfold: false, // 展开状态位
        }
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        cpu_list() {
            if (this.needAll) {
                return [{
                    [this.valueKey]: '',
                    [this.labelKey]: '不限'
                }].concat(this.list)
            } else {
                return this.list
            }
        },
        cpu_isActive() {
            return function (item) {
                return item[this.valueKey] == this.value
            }
        },
    },
    methods: {
        /**
         * @description: 选择子项
         * @param {Object} item 子项
         */
        selectItem(item) {
            if (item[this.valueKey] === this.cpu_value) return

            this.cpu_value = item[this.valueKey]
            this.$nextTick(() => {
                this.$emit('change', this.cpu_value)
                this.$parent.$emit('change', this.label, this.cpu_value)
            })
        },
        /**
         * @description: 切换展开折叠状态
         */
        toggleFold() {
            this.unfold = !this.unfold
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

$itemHeight: 32px;

.filter-item {
    display: -webkit-flex;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
}
.filterItem-label,
.content-item,
.filterItem-right {
    height: $itemHeight;
    line-height: $itemHeight;
}
.filterItem-label {
    min-width: 80px;
    color: #000000;
}
.filterItem-content {
    flex: 1;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    padding-left: 20px;
    overflow: hidden;

    .content-item {
        display: inline-block;
        padding-right: 10px;
        padding-left: 10px;
        cursor: pointer;

        &:hover {
            color: $col-theme;
        }
        &.content-item--active {
            border-radius: 4px;
            color: #ffffff;
            background-color: $col-theme;
        }
    }
    &.filterItem-content--fold {
        height: $itemHeight;
    }
}
.filterItem-right {
    color: #999999;
    margin-left: 20px;
    cursor: pointer;

    .icon {
        margin-left: 5px;
        -webkit-transition: transform 0.2s linear 0s;
        -moz-transition: transform 0.2s linear 0s;
        -o-transition: transform 0.2s linear 0s;
        transition: transform 0.2s linear 0s;
    }
    &.filterItem-right--unfold .icon {
        transform: rotate(180deg);
    }
}
</style>